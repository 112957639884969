.fulfillment-speed {
  display: flex;
  gap: 6px;
  color: black;
  padding: 3px 7px 3px 6px;
  border-radius: 4px;
  width: fit-content;
  border: 1px solid black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.fulfillment-speed.fast {
  background-color: #81c784;
}

.fulfillment-speed.moderate {
  background-color: #ffee58;
}

.fulfillment-speed.slow {
  background-color: #e57373;
}

.fulfillment-speed__icon {
  width: 16px;
  height: 16px;
}

.fulfillment-speed__text {
  margin: 0;
  line-height: 1.25;
  font-size: 0.8rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  user-select: none;
}
