.flex-center {
  display: flex;
  justify-content: center;
}

.shops-slider__expanded {
  display: grid;
  width: 100%;
  gap: 16px;
}

.shops-slider__container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}

.shops-slider__grid {
  position: relative;
  margin-right: calc(-1 * var(--gutter));
  margin-left: calc(-1 * var(--gutter));
  height: var(--wide_card_height);
}

.shops-slider__grid-loading {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 0 var(--gutter) 0 var(--gutter);
}

.shops-slider__grid.tall {
  height: var(--tall-card-height);
}

.shops-slider__grid.wide {
  height: var(--wide-card-height);
}

.shops-slider__grid.product {
  height: var(--product-card-height);
}

.shops-slider__grid .hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.shops-slider__grid .hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.shops-slider__icon-button {
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  outline: none;
  transition: all 0.2s ease-out;
  width: 3rem;
  z-index: 20;
}

.shops-slider__icon-button:hover,
.shops-slider__icon-button:focus {
  background: "rgba(0, 0, 0, 0.9)";
}
