@keyframes wipe-in-left {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:left"] {
  animation: 1s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-left both;
}

@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:right"] {
  animation: 1s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}

@keyframes wipe-out-right {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}

[transition-style="out:wipe:right"] {
  animation: 1s cubic-bezier(0.25, 1, 0.3, 1) wipe-out-right both;
}

@keyframes wipe-out-left {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 100% 0 0);
  }
}

[transition-style="out:wipe:left"] {
  animation: 1s cubic-bezier(0.25, 1, 0.3, 1) wipe-out-left both;
}

.visually-hidden {
  visibility: hidden;
}

.hero-shops__root {
  height: 85vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
}

.hero-shops__shop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-shops__image {
  min-width: 100vw;
  height: 40vh;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  padding: 8rem var(--gutter) 13rem var(--gutter);
}

.hero-shops__buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  margin-top: 10vh;
  position: absolute;
  padding: 0 var(--gutter);
}

.hero-shops__buttons__icon-button {
  align-items: center;
  backdrop-filter: blur(8px);
  background: rgba(250, 250, 250, 0.5);
  border: none;
  border-radius: 50%;
  color: inherit;
  display: flex;
  font: inherit;
  height: 32px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 32px;
  transition: all 0.1s ease-in;
  transform: translateY(0);
}

.hero-shops__buttons__icon-button:hover {
  background: rgba(250, 250, 250, 0.9);
  cursor: pointer;
  transform: translateY(-2px);
}

.hero-shops__talents {
  display: flex;
  gap: var(--spacing-4);
  overflow-x: auto;
  padding: 1rem var(--gutter);
  scrollbar-width: thin;
  scrollbar-color: #dddddd transparent;
}

.hero-shops__talents::-webkit-scrollbar {
  width: 0.5rem;
}

.hero-shops__talents::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 0.25rem;
}

.hero-shops__talents::-webkit-scrollbar-track {
  background-color: transparent;
}

.hero-shops__talent {
  position: relative;
}

.hero-shops__talent__event {
  position: absolute;
  padding: 0.5rem;
  background: white;
}

.hero-shops__talent-image {
  border-radius: var(--spacing-1);
  object-fit: cover;
  box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.1),
    0 0 2px 0 rgba(16, 150, 230, 0.1);
  transition: box-shadow 0.1s ease-in;
}

.hero-shops__cta {
  padding: 0 var(--gutter);
  max-width: 72ch;
  margin-left: auto;
}

.hero-shops__cta-button-container {
  display: flex;
  gap: var(--spacing-4);
}

.hero-shops__content {
  backdrop-filter: blur(8px);
  background: radial-gradient(
    circle at 50% 50%,
    rgba(250, 250, 250, 0.75),
    rgba(250, 250, 250, 0.5)
  );
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacing-4);
  margin-top: -11rem;
  padding: 1rem 0;
}

.hero-shops__cta-button__button {
  align-items: center;
  background: linear-gradient(268.66deg, #1096e6 -7.68%, #e45599 99.38%),
    linear-gradient(268.66deg, #358fab -7.68%, #a8dcec 99.38%), #ffffff;
  border-radius: 20px;
  box-shadow: 0 1px 4px 1px rgba(80, 80, 100, 0.1);
  color: white;
  display: flex;
  font: inherit;
  font-size: 1rem;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: auto;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s ease-in;
  width: 100%;
  z-index: 2;
}

.hero-shops__cta-button__button:hover {
  filter: brightness(1.05);
  box-shadow: 0 2px 8px 2px rgba(80, 80, 100, 0.2);
}

@media (orientation: portrait) and (min-height: 900px) {
  .hero-shops__root {
    height: 67.5vh;
  }
}

@media (orientation: landscape) or (min-width: 801px) {
  .hero-shops__root {
    height: 88vh;
  }

  .hero-shops__buttons {
    margin-top: 25vh;
  }

  .hero-shops__image {
    height: 72vh;
  }

  .hero-shops__buttons__icon-button {
    height: 64px;
    width: 64px;
  }

  .hero-shops__cta-button__button {
    height: 56px;
    padding: 0 32px;
    border-radius: 28px;
  }

  .hero-shops__content {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    grid-template-columns: 1fr 1.618fr;
    gap: var(--spacing-6);
  }

  .hero-shops__talents {
    padding-left: 0;
  }

  .hero-shops__cta {
    padding: 1rem var(--gutter);
    text-align: right;
  }

  .hero-shops__cta-button-container {
    justify-content: flex-end;
    padding-right: 0;
  }

  .hero-shops__cta-button-container a {
    width: unset;
  }
}

@media (orientation: landscape) and (min-width: 1440px) {
  .hero-shops__root {
    height: 88vh;
  }
}

@media (min-width: 1601px) {
  .hero-shops__cta-button__button {
    height: 64px;
    padding: 0 40px;
    border-radius: 32px;
  }
}
